<template>
  <!-- 前端工具 -->
  <div class="upload-oss-wrap">
    <el-input
      v-model="url"
      placeholder="请输入租户下路径(前后不需要写/)"
      style="width: 30%"
    ></el-input>
    <el-upload
      class="avatar-uploader"
      action=""
      :show-file-list="true"
      :multiple="true"
      accept=".jpg,jpeg,.png"
      :http-request="uploadFile"
      :on-error="handleFileError"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import { ossAuthorization } from "@/utils/oss";
export default {
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    ossAuthorization.call(this);
  },
  methods: {
    uploadFile(param) {
      let file = param.file;
      this.client
        .put(`Frontend/${this.url}/${file.name}`, file)
        .then((ress) => {
          this.$message.success("上传成功");
        });
    },
    handleFileError(err) {
      console.log(err);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-oss-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.avatar-uploader {
  margin-top: 20px;
}
</style>
